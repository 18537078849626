import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography, useTheme, Select, MenuItem, FormControl } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import "./Users.css";

const Users = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [editUserData, setEditUserData] = useState(null); // State to store data of user being edited
  const [showEditForm, setShowEditForm] = useState(false); // State to control the visibility of edit form

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await fetch("https://api.c2i.tn:5000/users");
      if (!response.ok) {
        throw new Error("Failed to fetch users");
      }
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleDeleteUser = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this user?");
    if (!confirmDelete) return;
    try {
      const response = await fetch(`https://api.c2i.tn:5000/users/${id}`, {
        method: "DELETE",
      });
      if (!response.ok) {
        throw new Error("Failed to delete user");
      }
      fetchUsers();
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const handleUpdateUser = (user) => {
    setEditUserData(user); // Set data of user being edited
    setShowEditForm(true); // Show edit form
  };

  const handleCloseEditForm = () => {
    setShowEditForm(false); // Close edit form
  };

  const handleEditFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`https://api.c2i.tn:5000/users/${editUserData.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(editUserData),
      });
      if (!response.ok) {
        throw new Error("Failed to update user");
      }
      setShowEditForm(false); // Close edit form after successful update
      fetchUsers(); // Fetch updated user list
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  const handleAddUser = () => {
    navigate('/form'); // Navigate to form page
  };

  const columns = [
    { field: "Name", headerName: "Name & LastName", flex: 1 },
    { field: "type", headerName: "Type Of Problem", flex: 1 },
    { field: "genre", headerName: "Ligne Type", flex: 1 },
    { field: "level", headerName: "Gradar", flex: 1 },
    { field: "post", headerName: "Post", flex: 1 },
    { field: "tlp", headerName: "Phone", flex: 1 },
    { field: "date", headerName: "Created At", flex: 1 },

  ];

  return (
    <Box m="20px"  style={{ borderRadius: '80px', marginTop: '-8px' }} > 
       <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Header title="SUPERVISOR" />
</Box>
      <Box
        m="40px 0 0 0"
         style={{ borderRadius: '80px', marginTop: '-25px' }}
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid rows={users} columns={columns} getRowId={(row) => row.id} components={{ Toolbar: GridToolbar }} />
      </Box>

      {/* Edit Form */}
      {showEditForm && (
        <Box className="edit-form-overlay">
          <Box className="edit-form">
            <Typography className="titr" variant="h6">Edit User</Typography>
            <form onSubmit={handleEditFormSubmit}>
              {/* Input fields for editing user data */}
              <FormControl fullWidth margin="normal">
                <label>Name & LastName:</label>
                <input
                className=".input"
                  type="text"
                  value={editUserData.Name}
                  onChange={(e) => setEditUserData({ ...editUserData, Name: e.target.value })}
                />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <label>Type Of Problem:</label>
                <Select
                  value={editUserData.type}
                  onChange={(e) => setEditUserData({ ...editUserData, type: e.target.value })}
                >
                  <MenuItem value="Qualité">Qualité</MenuItem>
                  <MenuItem value="Prüftechnik">Prüftechnik</MenuItem>
                  <MenuItem value="Logistique">Logistique</MenuItem>
                  <MenuItem value="Maintenance">Maintenance</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <label>Ligne Type:</label>
                <Select
                  value={editUserData.genre}
                  onChange={(e) => setEditUserData({ ...editUserData, genre: e.target.value })}
                >
                  <MenuItem value="MR">MR</MenuItem>
                  <MenuItem value="IR">IR</MenuItem>
                  <MenuItem value="VIDEO">VIDEO</MenuItem>
                  <MenuItem value="ARBEIT">ARBEIT</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <label>Gradar:</label>
                <Select
                  value={editUserData.level}
                  onChange={(e) => setEditUserData({ ...editUserData, level: e.target.value })}
                >
                  <MenuItem value="Technician">Technician</MenuItem>
                  <MenuItem value="Supervise">Supervisor</MenuItem>
                  <MenuItem value="Assistant Director">Assistant Director</MenuItem>
                  <MenuItem value="Director">Director</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <label>Post:</label>
                <Select
                  value={editUserData.post}
                  onChange={(e) => setEditUserData({ ...editUserData, post: e.target.value })}
                >
                  <MenuItem value="A">A</MenuItem>
                  <MenuItem value="B">B</MenuItem>
                  <MenuItem value="C">C</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <label>Phone:</label>
                <input
                className=".input"
                  type="text"
                  value={editUserData.tlp}
                  onChange={(e) => setEditUserData({ ...editUserData, tlp: e.target.value })}
                />
              </FormControl>
              <Box className="edit-form-buttons">
                <Button type="submit" variant="contained" color="primary">
                  Save
                </Button>
                <Button onClick={handleCloseEditForm} variant="outlined" color="secondary">
                  Cancel
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Users;
