import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TextField, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";

const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  Name: yup.string().required("Name is required"),
  type: yup.string().required("Type is required"),
  genre: yup.string().required("Genre is required"),
  level: yup.string().required("Level is required"),
  post: yup.string().required("Post is required"),
  tlp: yup.string().matches(phoneRegExp, "Phone number is not valid").required("Phone is required"),
});

const initialValues = {
  Name: "",
  type: "",
  genre: "",
  level: "",
  post: "A",
  tlp: ""
};

const Form = () => {
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleSubmit = async (values) => {
    try {
      const response = await fetch('https://api.c2i.tn:5000/users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });
      if (!response.ok) {
        throw new Error('Failed to add user');
      }
      navigate('/contacts');
    } catch (error) {
      console.error('Error adding user:', error);
    }
  };

  return (
    <Box m="20px">
      <Header title="CREATE USER" subtitle="Create a New User Profile" />

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(2, minmax(0, 1fr))"
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Name & LastName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Name}
                name="Name"
                error={!!touched.Name && !!errors.Name}
                helperText={touched.Name && errors.Name}
              />

              <FormControl fullWidth variant="filled" error={!!touched.type && !!errors.type}>
                <InputLabel>Type Of Problem</InputLabel>
                <Select
                  label="Type Of Problem"
                  value={values.type}
                  onChange={handleChange}
                  name="type"
                >
                  <MenuItem value="Qualité">Qualité</MenuItem>
                  <MenuItem value="Prüftechnik">Prüftechnik</MenuItem>
                  <MenuItem value="Logistique">Logistique</MenuItem>
                  <MenuItem value="Maintenance">Maintenance</MenuItem>
                </Select>
                {touched.type && errors.type && <p style={{ color: 'red' }}>{errors.type}</p>}
              </FormControl>

              <FormControl fullWidth variant="filled" error={!!touched.genre && !!errors.genre}>
                <InputLabel>Ligne Type</InputLabel>
                <Select
                  label="Ligne Type"
                  value={values.genre}
                  onChange={handleChange}
                  name="genre"
                >
                  <MenuItem value="MR">MR</MenuItem>
                  <MenuItem value="IR">IR</MenuItem>
                  <MenuItem value="VIDEO">VIDEO</MenuItem>
                  <MenuItem value="ARBEIT">ARBEIT</MenuItem>
                </Select>
                {touched.genre && errors.genre && <p style={{ color: 'red' }}>{errors.genre}</p>}
              </FormControl>

              <FormControl fullWidth variant="filled" error={!!touched.level && !!errors.level}>
                <InputLabel>Gradar</InputLabel>
                <Select
                  label="Gradar"
                  value={values.level}
                  onChange={handleChange}
                  name="level"
                >
                  <MenuItem value="Technician">Technician</MenuItem>
                  <MenuItem value="Supervise">Supervisor</MenuItem>
                  <MenuItem value="Assistant Director">Assistant Director</MenuItem>
                  <MenuItem value="Director">Director</MenuItem>
                </Select>
                {touched.level && errors.level && <p style={{ color: 'red' }}>{errors.level}</p>}
              </FormControl>

              <FormControl fullWidth variant="filled" error={!!touched.post && !!errors.post}>
                <InputLabel>Post</InputLabel>
                <Select
                  label="Post"
                  value={values.post}
                  onChange={handleChange}
                  name="post"
                >
                  <MenuItem value="A">A</MenuItem>
                  <MenuItem value="B">B</MenuItem>
                  <MenuItem value="C">C</MenuItem>
                </Select>
                {touched.post && errors.post && <p style={{ color: 'red' }}>{errors.post}</p>}
              </FormControl>

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Phone"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.tlp}
                name="tlp"
                error={!!touched.tlp && !!errors.tlp}
                helperText={touched.tlp && errors.tlp}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Create New User
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default Form;
