import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import styled from "styled-components";
import alarmOnImage from "./Image/AlarmeOFF-Photoroom.png";
import alarmOnImage2 from "./Image/AlarmeOnn-Photoroom.gif";
import AlarmeLON from "./Image/AlarmeLOnnn-Photoroom.gif";
import AlarmeLOFF from "./Image/AlarmeLOFF-Photoroom.png";
import AlarmeAPUON from "./Image/Picture1-Photoroom.gif";
import AlarmeAPUOFF from "./Image/Picture1-Photoroom-1.gif";
import AlarmeMUON from "./Image/AlarmeMOnn-Photoroom.gif";
import AlarmeMOFF from "./Image/AlarmeOFF-Photoroom1.png";
import "./MqttSubscriber.css";

const StyledMqttSubscriber = styled.div`
  background-color: ${(props) => (props.darkMode ? "#222" : "#fff")};
  color: ${(props) => (props.darkMode ? "#fff" : "#000")};
`;

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [balance, setBalance] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
 
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://api.c2i.tn:5000/latest-data"); // Change the URL if your API endpoint is different
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 2000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (searchTerm.trim() === "") {
      setFilteredData(data); // If search term is empty, show all data
    } else {
      const filtered = data.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
    }
  }, [searchTerm, data]);
  
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const balanceStyle = {
    color: balance > 500 ? 'rgb(31, 167, 92)' : 'rgb(181, 19, 19)'
    
  };
  return (
    <Box style={{ borderRadius: '80px', marginTop: '-20px', marginLeft: '10px', marginRight: '10px' }}>

      {/* HEADER */}
     

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        {/* ROW 2 */}
        <Box
          gridColumn="span 30"
          gridRow="span 60"
          backgroundColor={colors.primary[400]}
        >
        <Box display="flex" justifyContent="space-between" alignItems="center" style={{ marginLeft: '85%' }}> 
        {/* <Header title="DASHBOARD" /> */}
     <input
         type="text"
         placeholder="Search..."
         value={searchTerm}
         onChange={handleSearch}
         className="custom-search-input"
       />
      </Box>
        
       
     
        
      
       <div className="dhia">
         {filteredData.map((item, index) => (
           <fieldset className="fid" key={index}>
 
 <legend>
   <h3>
     <span>{item.name}</span> 
     <span style={{ marginLeft: '2rem' }}>SHIFT : {item.post}</span>
   </h3>
 </legend>
 
              
             <div className="contenue">
               <table  
                 className="tab1"
                 style={{
                   backgroundColor: "rgba(243, 43, 43, 0.541)",
                   borderRadius: 10 + "px",
                 }}
               >
                 <thead>
                   <tr>
                     <td colSpan="4">Qualité </td>
                   </tr>
                 </thead>
                 <tbody>
                   <tr>
                     <td>Temps</td>
                     <td></td>
                     <td>Freq</td>
                   </tr>
 
                   <tr key={index}>
                     <td>{item.TQ}</td>
                     <td>
                       {item.AQ === "0" ? (
                         <img
                           src={alarmOnImage}
                           alt="Alarm Off"
                           width="32"
                           height="32"
                           style={{ marginTop: "-30px" }}
                         />
                       ) : (
                         <img
                           src={alarmOnImage2}
                           alt="Alarm On"
                           width="32"
                           height="32"
                           style={{ marginTop: "-30px" }} // Use style prop to provide CSS styles
                         />
                       )}
                     </td>
                     <td>{item.FQ}</td>
                   </tr>
                 </tbody>
               </table>
           
               <table  
                 className="tab3"
                 style={{
                   backgroundColor: "rgb(223, 232, 253)",
                   borderRadius: 10 + "px",
                 }}
               >
                 <thead>
                   <tr>
                     <td colSpan="4">Prüftechnik</td>
                   </tr>
                 </thead>
                 <tbody>
                   <tr>
                     <td>Temps</td>
                     <td></td>
                     <td>Freq</td>
                   </tr>
 
                   <tr key={index}>
                     <td>{item.TPU}</td>
                     <td>
                       {item.AP === "0" ? (
                         <img
                           src={alarmOnImage}
                           alt="Alarm Off"
                           width="32"
                           height="32"
                           style={{ marginTop: "-20px" }}
                         />
                       ) : (
                         <img
                           src={AlarmeAPUON}
                           alt="Alarm On"
                           width="32"
                           height="32"
                           style={{ marginTop: "-20px" }}
                         />
                       )}
                     </td>
                     <td>{item.FP}</td>
                   </tr>
                 </tbody>
               </table>
               <table  
                 className="tab2"
                 style={{
                   backgroundColor: "rgba(209, 146, 51, 0.959)",
                   borderRadius: 10 + "px",
                 }}
               >
                 <thead>
                   <tr>
                     <td colSpan="4">Logistique</td>
                   </tr>
                 </thead>
                 <tbody>
                   <tr>
                     <td>Temps</td>
                     <td></td>
                     <td>Freq</td>
                   </tr>
 
                   <tr key={index}>
                     <td>{item.TL}</td>
                     <td>
                       {item.AL === "0" ? (
                         <img
                           src={alarmOnImage}
                           alt="alarmOnImage"
                           width="32"
                           height="32"
                           style={{ marginTop: "-20px" }}
                         />
                       ) : (
                         <img
                           src={AlarmeAPUOFF}
                           alt="Alarm On"
                           width="32"
                           height="32"
                           style={{ marginTop: "-20px" }}
                         />
                       )}
                     </td>
                     <td>{item.FL}</td>
                   </tr>
                 </tbody>
               </table>
               <table   className="tab4"  style={{
                   backgroundColor: "rgb(208, 208, 226)",
                   borderRadius: 10 + "px",
                 }}
               >
                 <thead>
                   <tr>
                     <td colSpan="4">Maintenance</td>
                   </tr>
                 </thead>
                 <tbody>
                   <tr>
                     <td>Temps</td>
                     <td></td>
                     <td>Freq</td>
                   </tr>
 
                   <tr key={index}>
                     <td>{item.TM}</td>
                     <td className="nnn">
                       {item.AM === "0" ? (
                         <img
                           src={alarmOnImage}
                           alt="Alarm Off"
                           width="32"
                           height="32"
                           style={{ marginTop: "-30px" }}
                         />
                       ) : (
                         <img
                           src={AlarmeMUON}
                           alt="Alarm On"
                           width="32"
                           height="32"
                           style={{ marginTop: "-30px" }}
                         />
                       )}
                     </td>
                     <td>{item.FM}</td>
                   </tr>
                 </tbody>
               </table>
             </div>
           </fieldset>
         ))}
       </div>
    
        </Box>
        
      </Box>
    </Box>
  );
};

export default Dashboard;
