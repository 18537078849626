import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { motion } from "framer-motion";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Brush
} from "recharts";

const StyledChartContainer = styled(motion.div)`
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  background-color: ${(props) => (props.darkMode ? "#222" : "#fff")};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
`;

const ChartTitle = styled.h2`
  text-align: center;
  margin-bottom: 20px;
  color: ${(props) => (props.darkMode ? "#fff" : "#000")};
`;

const DateInput = styled.input`
  margin: 10px;
  padding: 5px;
`;

const Select = styled.select`
  margin: 10px;
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

export default function Historique({ darkMode }) {
  const [histoData, setHistoData] = useState([]);
  const [name, setName] = useState("");
  const [filterType, setFilterType] = useState("name");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    fetchData();
  }, [name, startDate, endDate]);

  const fetchData = async () => {
    try {
      let url = "https://api.c2i.tn:5000/Historique";
      let params = {};

      if (startDate && endDate) {
        params.startDate = startDate;
        params.endDate = endDate;
      } else if (startDate) {
        params.startDate = startDate;
      } else if (endDate) {
        params.endDate = endDate;
      }

      if (name) {
        params.name = name.toLowerCase();
      }

      const response = await axios.get(url, { params });

      if (response.data.length === 0) {
        setHistoData([]);
        setErrorMessage(response.data.message || "No data found");
      } else {
        setHistoData(response.data);
        setErrorMessage("");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setErrorMessage("Error fetching data. Please try again later.");
    }
  };

  const handleFilterTypeChange = (newFilterType) => {
    setFilterType(newFilterType);
    setName("");
    setStartDate("");
    setEndDate("");
  };

  return (
    <StyledChartContainer
      darkMode={darkMode}
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      

      <Select
        value={filterType}
        onChange={(e) => handleFilterTypeChange(e.target.value)}
      >
        <option value="name">Filter by Name</option>
        <option value="date">Filter by Date</option>
      </Select>

      {filterType === "name" && (
        <input
          type="text"
          className="inp"
          placeholder="Enter name to filter"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      )}

      {filterType === "date" && (
        <>
          <DateInput
        className="inp"
            type="text"
            placeholder="Start Date (YYYY-MM-DD)"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <DateInput
            className="inp"
            type="text"
            placeholder="End Date (YYYY-MM-DD)"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </>
      )}

      {errorMessage && <p>{errorMessage}</p>}

      {!errorMessage && (
        <ResponsiveContainer width="100%" height={500}>
          <BarChart
            data={histoData}
            margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Brush dataKey="date" height={30} width={1200}  stroke="#8884d8" />
            <Bar dataKey="AQ" stackId="name" fill="#ff6666" barSize={50} />
            <Bar dataKey="AL" stackId="name" fill="#ffd699" barSize={50} />
            <Bar dataKey="AM" stackId="name" fill="#8ab9ff" barSize={50} />
            <Bar dataKey="APU" stackId="name" fill="#b8b4b8" barSize={50} />
            <Bar dataKey="AP" stackId="name" fill="#4d94ff" barSize={50} />
          </BarChart>
        </ResponsiveContainer>
      )}
    </StyledChartContainer>
  );
}
