import React, { useState, useEffect, useContext } from "react";
import { Box, IconButton, useTheme } from "@mui/material";
import { ColorModeContext, tokens } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import AlarmeAPUOFF from "../dashboard/Image/img.png";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  // State to hold current date and time
  const [currentTime, setCurrentTime] = useState(new Date());

  // Update the time every second
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timer); // Cleanup on component unmount
  }, []);

  // Function to format date and time with capitalized day and month
  const formatDateTime = (date) => {
    const options = { 
      weekday: "long", 
      year: "numeric", 
      month: "long", 
      day: "numeric", 
      hour: "2-digit", 
      minute: "2-digit", 
      second: "2-digit" 
    };
    const formattedDate = date.toLocaleString(undefined, options);

    // Capitalize the first letter of each word in the formatted date string
    return formattedDate.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      <Box display="flex" borderRadius="3px">
        <Box display="flex" justifyContent="center" alignItems="center">
          <img
            alt="profile-user"
            width="130px"
            height="70px"
            src={`../../assets/user.png`}
          />
        </Box>
      </Box>

      <Box display="flex" alignItems="center" p={1}>
        <span>{formatDateTime(currentTime)}</span>
      </Box>

      <img
        src={AlarmeAPUOFF}
        alt="Alarm Off"
        width="180"
        height="130"
        style={{ marginTop: "-60px" }}
      />

      {/* ICONS */}
      {/* 
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
      </Box>  
      */}
    </Box>
  );
};

export default Topbar;
