import React, { useState, useEffect } from 'react';
import { Box, Typography, useTheme, Select, MenuItem, FormControl } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";

const PostLigne = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [names, setNames] = useState([]);

  // Fetch all names from the test table when the component mounts
  useEffect(() => {
    fetchNames();
  }, []);

  // Function to fetch all names from the test table
  const fetchNames = async () => {
    try {
      const response = await fetch('https://api.c2i.tn:5000/test-data');
      if (!response.ok) {
        throw new Error('Failed to fetch names');
      }
      const data = await response.json();
      setNames(data);
    } catch (error) {
      console.error('Error fetching names:', error);
    }
  };

  // Function to update the post value for a specific name
  const updatePost = async (name, newPostValue) => {
    try {
      if (!name) {
        throw new Error('ID is undefined');
      }
      const response = await fetch(`https://api.c2i.tn:5000/update-post/${name}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ post: newPostValue }),
      });
      if (!response.ok) {
        throw new Error('Failed to update post value');
      }
      // Refresh the list of names after updating the post value
      fetchNames();
    } catch (error) {
      console.error('Error updating post value:', error);
    }
  };

  const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    {
      field: "post",
      headerName: "Post",
      flex: 1,
      renderCell: (params) => (
        <FormControl fullWidth>
          <Select
            value={params.row.post}
            onChange={(e) => updatePost(params.row.name, e.target.value)}
            sx={{
              backgroundColor: colors.primary[400],
              color: theme.palette.mode === 'dark' ? '#fff' : '#333',
            }}
          >
            <MenuItem value="A">A</MenuItem>
            <MenuItem value="B">B</MenuItem>
            <MenuItem value="C">C</MenuItem>
          </Select>
        </FormControl>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header title="Post Ligne"  />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid rows={names} columns={columns} getRowId={(row) => row.name} />
      </Box>
    </Box>
  );
};

export default PostLigne;
