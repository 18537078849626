import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { motion } from "framer-motion";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const StyledChartContainer = styled(motion.div)`
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  background-color: ${(props) => (props.darkMode ? "#222" : "#fff")};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 100%;
`;

const ChartTitle = styled.h2`
  text-align: center;
  margin-bottom: 20px;
  color: ${(props) => (props.darkMode ? "#fff" : "#000")};
`;

const Chart = ({ darkMode, toggleDarkMode }) => {
  const [histoData, setHistoData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://api.c2i.tn:5000/Histo");
        setHistoData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 2000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <StyledChartContainer
      darkMode={darkMode}
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <ChartTitle darkMode={darkMode}>Histo Data Bar Chart</ChartTitle>
      <div style={{ overflowX: "auto" }}>
        <ResponsiveContainer width="100%" height={500}>
          <BarChart
            data={histoData}
            margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="AQ" fill="#ff6666" barSize={50} />
            <Bar dataKey="AL" fill="#ffd699" barSize={50} />
            <Bar dataKey="AM" fill="#8ab9ff" barSize={50} />
            <Bar dataKey="APU" fill="#b8b4b8" barSize={50} />
            <Bar dataKey="AP" fill="#4d94ff" barSize={50} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </StyledChartContainer>
  );
};

export default Chart;
