import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css"; // Import login styles

function Logine({ setIsLoggedIn }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async () => {
    setIsLoading(true); // Set loading state to true when login starts
 
    setIsLoading(true);
    setError('');

    try {
      const response = await fetch("https://api.c2i.tn:5000/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Response data:', data);  // Log the entire response data

        // Check if data contains the role
        if (data.role) {
          console.log('User role:', data.role);

          // Check the user's role and navigate accordingly
          if (data.role === 'user') {
            navigate("/Dashboard");
          } else {
            navigate("/Dashboard2");
          }

          setIsLoggedIn(true);
        } else {
          console.error('Role not found in response:', data);
          setError('Unexpected response from server. Please try again.');
        }
      } else {
        // Login failed, display error message
        const errorData = await response.json();
        setError(errorData.message); // Set error state with error message from backend
      }
    } catch (error) {
      console.error("Login failed:", error);
      setError("Login failed. Please try again."); // Set generic error message
    } finally {
      setIsLoading(false); // Set loading state to false when login finishes
    }
  };
  return (
    <div className="login-container2">
      <div className="login-form2">
        <h2>Welcome Back!</h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleLogin();
          }}
        >
          <div className="input-group2">
            <input
              type="text"
              className="use"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Username"
              required
            />
          </div>
          <div className="input-group2">
            <input
              className="ps"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              required
            />
          </div>
          {error && <p className="error-message">{error}</p>}{" "}
          {/* Display error message if error state is not null */}
          <button
          id="bou"
            className={isLoading ? "btn-loading" : ""}
            disabled={isLoading}
             >
            {isLoading ? "Logging in..." : "Login"}
          </button>
        </form>
      </div>
    </div>
  );
}

export default Logine;
